import { Box, Button, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { getUrlProps } from "helpers/landing"

export const StyledUnderlineLink = styled(Button).attrs((props) => ({
  forwardedAs: Link,
  justifyContent: "left",
  textDecoration: "underline",
  variant: "text",
  ...props,
}))`
  &:hover {
    text-decoration: none;
    ${(props) =>
      props.color &&
      `
      color: ${props.color};
    `}
  }
`

const UnderlineLink = styled(Typography).attrs((props) => ({
  display: "inline-block",
  fontSize: "18px",
  pb: { _: 1, desktop: 0 },
  ...props,
}))`
  text-decoration: underline;
  :hover,
  :active {
    text-decoration: none;
    ${(props) => props.color && `color: ${props.color};`}
  }
  ${(props) => props.$inline_style}
`

const BannerCta = styled(Button).attrs((props) => ({
  bg: "white",
  fontSize: "18px",
  height: "48px",
  letterSpacing: "0.5px",
  outline: "black",
  width: { _: "100%", tablet: "auto" },
  ...props,
}))`
  padding: 14px 48px;
`

const FeaturedCta = styled(Button).attrs((props) => ({
  bg: "rgba(0, 0, 0, 0.3)",
  border: "1px solid white",
  color: "white",
  fontSize: "16px",
  fontWeight: "500",
  letterSpacing: "1px",
  lineHeight: "18px",
  ...props,
}))`
  padding: 6px 24px;
  ${(props) => props.theme.mediaQueries.tablet`
    padding: 6px 40px;
  `}
`

const StyledLink = ({
  button_type,
  inline_style,
  type,
  url,
  children,
  style,
  linkClickData,
  onClick,
  ...props
}) => {
  const urlProps = getUrlProps(url, linkClickData, onClick)

  if (type === "underline") {
    return (
      <UnderlineLink $inline_style={inline_style} {...urlProps} {...style}>
        {children}
      </UnderlineLink>
    )
  } else if (type === "button") {
    if (button_type === "featured_cta") {
      return (
        // Featured Slide is wrapped by an anchor tag so
        // the CTA is just for show and does not need urlProps
        <FeaturedCta {...style} {...props}>
          {children}
        </FeaturedCta>
      )
    } else if (button_type === "banner_cta") {
      return (
        <BannerCta
          data-lc={linkClickData}
          forwardedAs={Link}
          to={url}
          {...style}
          {...props}
        >
          {children}
        </BannerCta>
      )
    }
    return (
      <Button {...urlProps} {...style} {...props}>
        {children}
      </Button>
    )
  }
  return (
    <Box {...urlProps} {...style} {...props}>
      {children}
    </Box>
  )
}

StyledLink.propTypes = {
  button_type: PropTypes.string,
  children: PropTypes.node,
  inline_style: PropTypes.string,
  linkClickData: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  type: PropTypes.string,
  url: PropTypes.string,
}

export default StyledLink
