import { Box, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

const LandingList = ({ id, list }) => {
  if (!list?.items?.length) return null

  return (
    <Box
      display="grid"
      gridGap={{ _: "24px 40px", desktop: "80px 40px" }}
      gridTemplateColumns="auto 1fr"
      py={{ desktop: 5 }}
      role="list"
      {...list.style}
    >
      {list.items.map((item, i) => (
        <React.Fragment key={`${id}-list-item-${i}`}>
          <Typography as="span" role="non" variant="h2">
            {(i + 1).toString().padStart(2, "0")}
          </Typography>
          <Box role="listitem">
            <Typography
              dangerouslySetInnerHTML={{ __html: item.title }}
              mb="10px"
              variant="h3"
              {...item.style?.title_style}
            />
            <Typography
              dangerouslySetInnerHTML={{ __html: item.text }}
              variant="body"
              {...item.style?.text_style}
            />
          </Box>
        </React.Fragment>
      ))}
    </Box>
  )
}

LandingList.propTypes = {
  id: PropTypes.string,
  list: PropTypes.object,
}

export default LandingList
