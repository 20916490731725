import { Box } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React from "react"

import { retry } from "helpers/application"

import Image from "./image"
import StyledLink from "./link"
import List from "./list"
import Text from "./text"

const ArticleMarkdown = loadable(() =>
  retry(() => import("areas/article/components/article-markdown"))
)
const ResponsiveVideo = loadable(() =>
  retry(() => import("areas/landing/elements/landing-responsive-video"))
)

const Textarea = ({
  firstElement,
  element,
  lazyload,
  style,
  linkClickData,
  linkProps,
}) => (
  <Box bg={style?.containerBg} contain={element.contain} width="100%">
    <Box {...style}>
      {element.texts.map((text, i) => {
        const firstSubElement = i === 0 && firstElement

        if (text.type === "link") {
          return (
            <StyledLink
              button_type={text.button_type}
              inline_style={text.inline_style}
              key={`textarealink${i}`}
              linkClickData={linkClickData}
              style={text.style}
              type={text.base_style}
              url={text.url}
              {...linkProps}
            >
              {text.text}
            </StyledLink>
          )
        } else if (text.type === "image") {
          const image = (
            <Image
              firstElement={firstSubElement}
              image={text}
              key={`textarea-image-${i}`}
              lazyload={lazyload}
              linkClickData={linkClickData}
            />
          )

          if (text.url || linkProps) {
            return (
              <StyledLink
                aria-label={text?.alt}
                display="block"
                key={`textarea-image-link-${i}`}
                linkClickData={linkClickData}
                type="image"
                url={text.url}
                {...linkProps}
              >
                {image}
              </StyledLink>
            )
          }

          return image
        } else if (text.type === "paragraph") {
          return (
            <ArticleMarkdown key={`paragraph${i}`}>
              {text.content}
            </ArticleMarkdown>
          )
        } else if (text.type === "video") {
          return (
            <ResponsiveVideo
              id={`${element.id}-${i}`}
              key={`video${i}`}
              lazyload={lazyload}
              video={text}
            />
          )
        } else if (text.type === "list") {
          return <List id={`${element.id}-${i}`} key={`list${i}`} list={text} />
        } else {
          return (
            <Text
              firstElement={firstSubElement}
              key={`text${i}`}
              linkClickData={linkClickData}
              style={text.style}
              text={text}
            />
          )
        }
      })}
    </Box>
  </Box>
)

Textarea.propTypes = {
  element: PropTypes.object,
  firstElement: PropTypes.bool,
  lazyload: PropTypes.bool,
  linkClickData: PropTypes.string,
  linkProps: PropTypes.object,
  style: PropTypes.object,
}

export default Textarea
